<template>
	<section class="section--page flavour-intro">
		<v-container>
			<v-row class="align-start justify-space-between">
				<v-col class="d-md-none text-center">

					<router-link v-if="back" :to="back" class="h5 flavour-intro__back"><svgicon name="arrow-right" />Back to shop</router-link>

					<h1 v-html="nameHtml"></h1>
					<div class="size1 color-golden" v-html="chocoHtml"></div>
				</v-col>
				<v-col cols="12" md="6">
					<div class="flavour-intro__img mt-4 mt-md-0" :class="{'no-intro':!item.PHOTO_INTRO}">
						<v-img v-if="item.PHOTO_INTRO" :src="item.PHOTO_INTRO" contain class="flavour-intro__img-intro" :alt="item.NAME" />
						<div v-else class="pt-16">
							<div class="flavour-intro__img-bg" />

							<div class="flavour-intro__img-self">
								<router-link v-if="item.DETAIL_PICTURE" :to="{}" class="hover"/>
								<v-img :src="item.PREVIEW_PICTURE" :alt="item.NAME"
									   :class="{image:item.DETAIL_PICTURE}"/>
								<v-img v-if="item.DETAIL_PICTURE" :src="item.DETAIL_PICTURE" :alt="item.name"
									   class="cut"/>
							</div>
						</div>
					</div>
				</v-col>
				<v-col cols="12" md="5" lg="5" class="pt-md-8 z-index-1">

					<router-link v-if="back" :to="back" class="d-none d-md-inline-flex h5 flavour-intro__back">
						<svgicon name="arrow-right" />Back to shop</router-link>

					<h1 class="d-none d-md-block mt-4 mt-lg-12" v-html="nameHtml"></h1>
					<div class="mb-12 mt-2 d-none d-md-block text-uppercase size1" v-html="chocoHtml"></div>
					<p class="mx-auto mx-md-0 text-center text-md-start">{{item.DETAIL_TEXT}}</p>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
    export default {
        name: "flavour-info",
        props: ["code"],
        computed: {
            item() {
                return this.$store.state.page.FlavourController.item;

            },
			nameHtml() {
            	const item = this.item;
				//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
				let name = item.NAME;
				name = name.replace(/(^.+?)(in )?(dark|milk).+$/is, "$1");
				name = name.replace(/(^.+?)filled with .+$/is, "$1");
				name = name.replace(/(^.+?)\d+ g$/is, "$1");
				name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
				/*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
					name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
					if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
				}
				else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

				return name;
			},
			chocoHtml() {
				const item = this.item;
				//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
				let choco = null;
				if (item.NAME.match(/.+?filled with .+$/)) {
					choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/.+?(in )?(dark|milk).+$/)) {
					choco = item.NAME.replace(/.+?((in )?(dark|milk).+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/\d+ g$/)) {
					// todo make this related to item.WEIGHT
					choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
				} else if (item.NAME.match(/\d+ pcs$/)) {
					choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
					if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
				}

				return choco;
			},
			back() {
            	return this.$route.query?.back;
			}
        }
    }
</script>

<style lang="scss">
	.flavour-intro {
		width: 100%;
		overflow: hidden;

		&__back {
			position: relative;
			display: inline-flex;
			align-items: center;
			margin: 0 16px 40px 0;
			svg {
				width: 16px;
				transform: rotate(180deg);
				margin-right: 12px;
			}

			@include up($md) {
				left: -28px;
			}

			@include up($lg) {
				margin: 0;
			}
		}

		p {
			max-width: 400px;
		}

		&__img {
			position: relative;
			height: 350px;

			&.no-intro {
				height: 300px;
			}

			@include up($md) {
				height: 440px;

				&.no-intro {
					height: 440px;
				}
			}

			@include up($lg) {
				height: 520px;

				&.no-intro {
					height: 520px;
				}
			}

			&-intro {
				position: absolute;
				left: 50%;
				top: 0;
				width: 120%;
				height: 100%;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				transform: translateX(-50%);
				filter: brightness(1.01); // для скрытия серго фона jpg
				//background-image: url("~@/assets/Flavour/flavour-bg.jpg");
				z-index: 0;
				max-width: unset !important;

				@include up($md) {
					top: -60px;
					width: 500px;
					height: 500px;
				}

				@include up($lg) {
					top: -60px;
					width: 600px;
					height: 600px;
				}
			}

			&-bg {
				position: absolute;
				left: -7%;
				top: -5%;
				width: 110%;
				height: 100%;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				background-image: url("~@/assets/Flavour/flavour-bg.jpg");
				z-index: 0;

				@include up($md) {
					top: -2%;
					left: -34%;
					width: 680px;
				}

				@include up($lg) {
					top: -8%;
					left: -19%;
					width: 750px;
				}
			}

			&-self {
				width: 140px;
				margin: 0 auto;
				position: relative;

				@include up($md) {
					width: 240px;
				}

				@include up($lg) {
					width: 320px;
				}

				.hover {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					width: 100%;
					height: 100%;
					//background: rgba(0,0,0,0.2);
					cursor: pointer;

					&:hover ~ .cut {
						opacity: 1;
					}

					&:hover ~ .image {
						opacity: 0;
					}
				}

				.cut {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					margin: 0 auto;
					z-index: 1;
					opacity: 0;
				}

				.v-image {
					@include transition();
				}
			}
		}
	}

</style>