<template>
	<section class="flavour-spec">
		<v-container>
			<v-row>
				<v-col cols="12" md="6">
					<v-row class="text-center flavour-spec__grid">
						<!--<v-col cols="12" md="6" class="flavour-spec__grid-block">
							<h5>Shelf life</h5>
							<p class="h5 mt-1" v-html="item.SHELF_LIFE"></p>
						</v-col>-->
						<v-col cols="12" md="6" class="flavour-spec__grid-block">
							<h5>{{page.texts.PRODUCT_NET_WEIGHT.NAME}}</h5>
							<p class="h5 mt-1" v-html="netWeight"></p>
						</v-col>
						<!--<v-col cols="12" md="6" class="flavour-spec__grid-block">
							<h5>Temperature</h5>
							<p class="h5 mt-1" v-html="item.TEMPERATURE"></p>
						</v-col>-->
						<v-col cols="12" md="6" class="flavour-spec__grid-block">
							<h5>{{page.texts.PRODUCT_PRICE_PER_KG.NAME}}</h5>
							<p class="h5 mt-1" v-html="pricePerKg"></p>
						</v-col>
						<v-col cols="12" class="flavour-spec__grid-block">
							<h5>{{page.texts.PRODUCT_VALUE_100.NAME}}</h5>
							<p class="size2 mt-2">{{nutrition}}</p>
						</v-col>
						<v-col cols="12" class="flavour-spec__grid-block">
							<h5>{{page.texts.PRODUCT_ALLERGENS.NAME}}</h5>
							<p class="size2 mt-2" v-html="item.ALLERGENS"></p>
						</v-col>
						<v-col cols="12" class="flavour-spec__grid-block">
							<h5>{{page.texts.PRODUCT_INGREDIENTS.NAME}}</h5>
							<p class="size2 mt-2" v-html="ingredients"></p>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="6">
					<div class="flavour-spec__img">
						<v-img v-if="item.PHOTO_IMAGE" :src="item.PHOTO_IMAGE" :alt="item.NAME"/>
						<v-img v-else src="@/assets/box/bg-lg.jpg" :alt="item.NAME"/>
					</div>
				</v-col>
			</v-row>

		</v-container>
		<!--<img class="flavour-spec__img d-none d-sm-block d-lg-none" src="@/assets/Flavours/praline/bg-sm.jpg"
			 alt="Background">-->
	</section>
</template>

<script>
    export default {
        name: "flavour-specs",
        computed: {
            item() {
                return this.$store.state.page.FlavourController.item;
            },
			mini() {
				return this.$store.state.page.FlavourController.mini;
			},
			netWeight() {
            	const items = [this.item.NET_WEIGHT.replace(/,/,".")];
            	if ( this.mini?.NET_WEIGHT ) items.push(this.mini.NET_WEIGHT.replace(/,/,"."));
            	return items.join (" / ");
			},
			pricePerKg() {
				const items = [this.item.PRICE_PER_KG.replace(/,/,".")];
				if ( this.mini?.PRICE_PER_KG ) items.push(this.mini.PRICE_PER_KG.replace(/,/,"."));
				return items.join (" / ");
			},
            nutrition() {
                const texts = [];
                if (this.item.ENERGY_KJ && this.item.KCAL) texts.push("Energy " + this.item.ENERGY_KJ + " kj/" + this.item.KCAL + " kcal");
                if (this.item.FATS) texts.push("fat " + this.item.FATS + " g");
                if (this.item.CARBOHYDRATES) texts.push("carbohydrates " + this.item.CARBOHYDRATES + " g");
                if (this.item.PROTEINS) texts.push("proteins " + this.item.PROTEINS + " g");
                return texts.join(", ");
            },
            ingredients() {
                const i = this.item.INGREDIENTS;
                return i.replaceAll(/(milk|soy|lactose|hazelnuts|hazelnut|wheat|barley|ginger|peanuts|peanut|walnut|dairy)/isg, "<b>$1</b>");
            },
            page() {
                return this.$store.state.page.FlavourController;
            },
        }
    }
</script>

<style lang="scss">
	.flavour-spec {
		.container {
			margin-bottom: 128px;
		}

		&__grid {
			padding: 0 !important;;
			margin: 0 !important;;

			&-block {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				padding: 32px !important;
				margin: 0 !important;;
				border: 1px solid $gray-light;
				border-bottom: 0;

				&:last-child {
					border-bottom: 1px solid $gray-light;
				}

				h5, .h5 {
					color: $black;
				}

				b {
					//color: $black;
				}

				// first 4
				/*&:nth-child(-n+4) {
					p {

					}
				}*/

				@include up($md) {
					// todo right border on 3rd buggs
					&:nth-child(1), &:nth-child(3) {
						border-right: 0;
					}
				}
			}
		}

		&__img {
			position: relative;
			width: 100%;

			@include up($md) {
				height: 100%;
			}

			.v-image {
				width: 100%;
				height: 320px;

				@include up($sm) {
					height: 680px;
				}

				@include up($md) {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
				}
			}
		}

		/*&__img {
			width: 100%;
			height: 296px;
			//background: url("~@/assets/Flavours/praline/bg-xs.jpg");
			//background-image: url("~@/assets/box/bg-lg.jpg");
			background-position: center;
			background-size: cover;

			@include up($md) {
				height: 100%;
			}

			.v-image {
				height: 100%;
			}
		}*/
	}
</style>